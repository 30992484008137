import { memo } from 'react';
import { classNames } from '@/shared/lib/classNames/classNames';

import cls from './InfoComponent.module.scss';
import { FontColor, FontSize, FontWeight, HeaderTagType, Text } from '@/shared/ui/Text';
import { EMAIL, PHONE } from '@/shared/const/main_info';

interface InfoComponentProps {
   className?: string;
}

export const InfoComponent = memo((props: InfoComponentProps) => {
   const { className } = props;

   return (
      <article className={classNames(cls.InfoComponent, {}, [className])}>
         <div className={cls.container}>
            <Text className={cls.mainTitle} title={HeaderTagType.H_2} fontWeight={FontWeight.TEXT_700}>
               ЗЕМЛЯК - официальный сайт производителя погребов и кессонов в России
            </Text>
            <p>
               ТМ «Земляк» стала одним из первых производителей, поставляющих на российский рынок пластиковые
               резервуары для обустройства скважин и погребов.
            </p>
            <p>
               Эти конструкции становятся востребованными из-за их надежности, герметичности и долговечности,
               а адекватная цена бесшовных погребов из пластика и кессонов для скважин позволяет подобрать
               модель под свой бюджет.
            </p>
            <p>Несколько фактов о нас:</p>
            <ul className={cls.list}>
               <li>трехуровневый контроль качества выпускаемых товаров;</li>
               <li>
                  наши готовые погреба и кессоны из пластика по выгодной цене – лучшие решения для дач и
                  загородных домов (по мнению 99% специалистов по автономному водоснабжению);
               </li>
               <li>собственное производство и склад готовой продукции в г. Ивантеевка;</li>
               <li>12 квалифицированных инженеров и менеджеров, работающих в команде;</li>
               <li>
                  обширная клиентская база и множество положительных отзывов от благодарных клиентов,
                  обратившихся к нам.
               </li>
            </ul>
            <Text
               title={HeaderTagType.H_3}
               fontSize={FontSize.SIZE_20}
               fontWeight={FontWeight.TEXT_600}
               fontColor={FontColor.LIGHT_GREY}
               className={cls.title}
            >
               Продукция компании Земляк
            </Text>
            <p>
               Основная деятельность компании «Земляк» – выпуск пластиковых кессонов, погребов и гибридных
               изделий для хранения продовольствия, а также защиты скважин с питьевой водой.
            </p>
            <p>
               Готовые решения нашего производства, предназначенные для обустройства загородного дома или
               дачи, практически вытеснили недолговечные конструкции из древесины и металла.
            </p>
            <p>
               Кессонам и погребам из пластика, цена на которые ниже, чем у аналогов, не потребуется
               специальная обработка от коррозии.
            </p>
            <p>
               Они весят сравнительно немного, поэтому для монтажа не нужны какие-либо технические средства,
               только монтажные стропы.
            </p>
            <p>
               Продукция ТМ «Земляк» изготовлена из монолитного полипропилена, который надежно прослужит не
               один десяток лет (от 50-80 лет и больше) без необходимости профилактического ремонта или
               технического обслуживания.
            </p>
            <p>
               Этот материал отличается высокой стойкостью к гниению, перепадам температур, давлению грунта.
               Литая муфта, выполненная из авиационной резины, многократно повышает надежность установки
               конструкции на участке.:
            </p>
            <p>
               Сами изделия и их невидимые швы позволяют быть уверенными в герметичности конструкции, поэтому
               отсутствует необходимость обустройства гидроизоляции для защиты от попадания внутрь грунтовых
               вод, влаги или атмосферных осадков. К тому же внутрь не попадут насекомые или грызуны.
            </p>
            <p>
               Каждый продукт ТМ «Земляк» снабжен понятной инструкцией по эксплуатации, схемой для
               самостоятельного монтажа кессона или погреба на участке и видеопрезентацией.
            </p>
            <Text
               title={HeaderTagType.H_3}
               fontSize={FontSize.SIZE_20}
               fontWeight={FontWeight.TEXT_600}
               fontColor={FontColor.LIGHT_GREY}
               className={cls.title}
            >
               Пластиковые резервуары для скважин
            </Text>
            <p>
               Полипропиленовый кессон – специальное герметичное сооружение, используемое для защиты
               водонасосного оборудования от доступа грунтовых вод, влаги в виде природных осадков, грызунов,
               перепадов температур и промерзания в холодное время года.
            </p>
            <p>
               Они просты в установке и обслуживании, отличаются малым весом и долгим (свыше 50 лет) сроком
               службы.
            </p>
            <p>Монтаж пластиковых кессонов для скважин происходит в яме вокруг обсадной колонны.</p>
            <p>Внутри этого резервуара могут располагаться следующие элементы:</p>
            <ul className={cls.list}>
               <li>насос, подающий воду в дом;</li>
               <li>автоматика для управления;</li>
               <li>система фильтрации;</li>
               <li>манометр;</li>
               <li>запорная арматура;</li>
               <li>аккумуляторы;</li>
               <li>расширительный бачок.</li>
            </ul>
            <p>
               При монтаже пластикового кессона основная его часть вкапывается в землю и фиксируется там
               грунтозацепами.
            </p>
            <p>
               На поверхности остается только люк для облегчения доступа к находящемуся внутри оборудованию.
            </p>
            <p>
               При необходимости производится утепление стен и люка кессона. Также с внутренней стороны можно
               установить лестница для спуска внутрь, а также для проведения каких-либо наладочных или
               ремонтных работ.
            </p>
            <p>Пластиковый кессон ТМ «Земляк» представляет собой полый цилиндр.</p>
            <p>
               Его диаметр зависит от размеров расположенного внутри оборудования и индивидуальных пожеланий
               заказчика.
            </p>
            <p>
               При выборе параметров рекомендуется учитывать глубину промерзания грунта, особенности
               функционирования и обслуживания насосного оборудования, а также целый ряд иных деталей.
            </p>
            <Text
               title={HeaderTagType.H_3}
               fontSize={FontSize.SIZE_20}
               fontWeight={FontWeight.TEXT_600}
               fontColor={FontColor.LIGHT_GREY}
               className={cls.title}
            >
               Погреба из полипропилена
            </Text>
            <p>Конструкция будет полезна каждому собственнику дачи или загородного дома.</p>
            <Text fontWeight={FontWeight.TEXT_600}>
               В каталоге можно найти готовые погреба из пластика с ценой и фото следующих моделей:
            </Text>
            <ul className={cls.list}>
               <li>
                  <a
                     href='https://земляк.рф/product/pogreb-zemlyak-1200h1200h2400-mm-s-pryamym-vert-vhodom/'
                     target='_blank'
                     rel='noreferrer'
                  >
                     стандарт
                  </a>
               </li>
               <li>
                  <a
                     href='https://земляк.рф/product/pogreb-zemlyak-1500x1500x2400-mm-so-smeshhyonnym-gor-vhodom/'
                     target='_blank'
                     rel='noreferrer'
                  >
                     комфорт
                  </a>
               </li>
               <li>
                  <a
                     href='https://земляк.рф/product/pogreb-zemlyak-1500x1500x2400-mm-s-pristroennym-vhodom/'
                     target='_blank'
                     rel='noreferrer'
                  >
                     комфорт+
                  </a>
               </li>
               <li>
                  <a
                     href='https://земляк.рф/product/pogreb-zemlyak-1500x1500x2400-mm-so-smeshhyonnym-vert-vhodom/'
                     target='_blank'
                     rel='noreferrer'
                  >
                     премиум
                  </a>
               </li>
               <li>
                  <a
                     href='https://земляк.рф/product/pogreb-zemlyak-premium-plyus-1500x1500x2400-mm/?roistat_visit=2074074'
                     target='_blank'
                     rel='noreferrer'
                  >
                     премиум+
                  </a>
               </li>
            </ul>
            <p>Они отличаются габаритными размерами и комплектацией.</p>
            <p>
               Так что любой потребитель сможет легко подобрать для себя нужный вариант пластикового погреба
               для хранения продуктов питания.
            </p>
            <p>
               Данная продукция изготавливается из монолитного полипропилена, прочность которого на 15% выше,
               чем у вспененного. Готовый погреб из пластика подойдет для хранения овощей, фруктов,
               консервации, молочных и мясных продуктов.
            </p>
            <p>Размеры могут быть разными, в зависимости от потребностей заказчика.</p>
            <p>
               Перед тем, как купить погреб из пластика, необходимо учитывать толщину стен, надежность и
               прочность каркаса, материалы изготовления полок и лестницы, наличие освещения, вентиляции.
            </p>
            <p>
               Конструктивно готовый погреб из пластика, купить который можно в компании «Земляк» представляет
               собой герметичную конструкцию, оснащенную деревянными полками по всему периметру и специальной
               лестницей из дерева для удобства доступа внутрь погреба.
            </p>
            <p>Бесшовная и герметичная емкость позволяет не пропускать грунтовые воды внутрь.</p>
            <p>
               Погреб имеет крышку, а наличие вентиляционной системы обеспечивает приток свежего воздуха
               внутрь, помогает поддерживать свежесть фруктов и овощей.
            </p>
            <p>
               Предусмотрена встроенная система освещения, которая позволит легко и быстро расставить
               заготовки по полкам и ящикам, или найти нужные банки с консервацией.
            </p>
            <p>
               Бесшовные погреба из пластика от компании «Земляк» изготавливаются на современных чешских
               станках с ЧПУ, что позволяет избежать брака и неточностей конструкции.
            </p>
            <p>У нас вы также можете купить погреб из пластика с утеплением от производителя</p>
            <Text
               title={HeaderTagType.H_3}
               fontSize={FontSize.SIZE_20}
               fontWeight={FontWeight.TEXT_600}
               fontColor={FontColor.LIGHT_GREY}
               className={cls.title}
            >
               Функциональные погреба-кессоны
            </Text>
            <p>
               Владельцам дачных участков и загородных домов производитель ТМ «Земляк» предлагает гибридный
               вариант изделий из монолитного полипропилена.
            </p>
            <p>
               Эта модификация успешно сочетает в себе функционал емкости для защиты водонасосной системы и
               практичность продуктового хранилища.
            </p>
            <p>
               Погреб-кессон способен надежно защитить устье скважины автономного водоснабжения, и в то же
               время сохранить надолго продовольственный запас.
            </p>
            <p>
               Здесь же практично располагается все технологическое оборудование для подачи воды (систему
               фильтров, манометр, водяной насос, расширительный бак, автоматические устройства для подачи
               воды).
            </p>
            <Text
               title={HeaderTagType.H_3}
               fontSize={FontSize.SIZE_20}
               fontWeight={FontWeight.TEXT_600}
               fontColor={FontColor.LIGHT_GREY}
               className={cls.title}
            >
               Кессон для скважины купить
            </Text>
            <p>
               Компания «Земляк» предлагает широкий выбор кессонов для скважин и септиков в Самаре и области.
            </p>
            <p>
               Также мы предлагаем подбор оборудования для вашего типа скважины. Наши модели кессонов имеют
               различные типы закрытия, включая ключ и термит, чтобы обеспечить максимальную защиту от воды и
               других нежелательных элементов.
            </p>
            <p>Оплата производится в руб, также предлагаем акции.</p>
            <p>
               Главная наша цель — обеспечить вас высококачественными кессонами, помочь выбрать подходящую
               модель кессона. Мы предлагаем продажу кессонов с акциями с любым способом оплаты.
            </p>
            <p>Наши контакты на странице сайта.</p>
            <Text
               title={HeaderTagType.H_3}
               fontSize={FontSize.SIZE_20}
               fontWeight={FontWeight.TEXT_600}
               fontColor={FontColor.LIGHT_GREY}
               className={cls.title}
            >
               Как заказать установку кессона для скважины
            </Text>
            <p>
               Если вы планируете купить продукцию ТМ «Земляк», вам необходимо будет заказать монтаж кессона
               для скважины. Цена за работу зависит от сложности установки.
            </p>
            <p>
               В монтаж входят услуги: копка котлована, обрезка обсадной трубы, установка кессона и
               трубопровода, обсыпка пескоцементной смесью, установка оборудования.
            </p>
            <p>
               Для расчета точной стоимости услуги позвоните нам по номеру {PHONE} или напишите на e-mail
               &nbsp;
               <span className={cls.link}>{EMAIL}</span>.
            </p>
            <Text
               title={HeaderTagType.H_3}
               fontSize={FontSize.SIZE_20}
               fontWeight={FontWeight.TEXT_600}
               fontColor={FontColor.LIGHT_GREY}
               className={cls.title}
            >
               Этапы работы по монтажу кессона на скважину
            </Text>
            <ol className={cls.list}>
               <li className={cls.numStyle}>
                  Предварительные расчеты. На объект приезжает замерщик. Он определяет параметры скважины,
                  подбирает оборудование и делает расчет сметы. В компании «Земляк» смета учитывает все до
                  мельчайших нюансов, а цена монтажа кессона для скважины остается приемлемой и понятной
                  заказчику.
               </li>
               <li className={cls.numStyle}>
                  Поставка купленного изделия. Сам кессон или погреб из пластика, оборудование и комплектующие
                  привозим непосредственно в день установки. Отгрузка готовых изделий со склада в Ивантеевка
                  происходит строго по согласованной с заказчиком смете.
               </li>
               <li className={cls.numStyle}>
                  Выкапываем котлован под кессон и траншею для трубопровода в точном соответствии с
                  технологией.
               </li>
               <li className={cls.numStyle}>
                  Выравниваем дно котлована пескоцементной засыпкой в отношении 5:1 (5 частей песка, 1 часть
                  цемента) с обязательным последующим трамбованием.
               </li>
               <li className={cls.numStyle}>
                  Обрезаем обсадную трубу (высота трубы над плитой не больше 50 см) и устанавливаем кессон
                  так, чтобы труба попала в отверстие муфты. Положение установленного резервуара обязательно
                  проверяем уровнем по горизонтали и вертикали. В процессе монтажа наши специалисты
                  обеспечивают абсолютную герметичность ввода обсадной трубы в кессон.
               </li>
               <li className={cls.numStyle}>
                  Для предотвращения всплытия или деформации изделия приступаем к обратной обсыпке пластиковой
                  емкости пескоцеметной смесью с обязательной утрамбовкой.
               </li>
               <li className={cls.numStyle}>
                  Затем устанавливаем надёжное оборудование, которое обеспечит бесперебойное автоматическое
                  водоснабжение и максимально комфортную эксплуатацию скважины: фильтры для разных степеней
                  очистки, гидробак, манометры, реле сухого хода, аккумуляторы.
               </li>
               <li className={cls.numStyle}>
                  После этого мы сдаем готовую работу заказчику и подписываем акт приема.
               </li>
            </ol>
            <Text
               title={HeaderTagType.H_3}
               fontSize={FontSize.SIZE_20}
               fontWeight={FontWeight.TEXT_600}
               fontColor={FontColor.LIGHT_GREY}
               className={cls.title}
            >
               Преимущества продукции компании Земляк
            </Text>
            <p>Основными достоинствами ТМ «Земляк» являются:</p>
            <ul className={cls.list}>
               <li>выгодная цена на изготовление и монтаж кессона или погреба;</li>
               <li>постоянные акции и сезонные скидки;</li>
               <li>предоставление полной технической документации на любую конструкцию;</li>
               <li>возможность заказать монтаж кессона для скважины по выгодной цене;</li>
               <li>большой выбор качественных пластиковых изделий;</li>
               <li>быстрая доставка в любую точку страны;</li>
               <li>наличие сертификатов, подтверждающих соответствие международным стандартам качества;</li>
               <li>
                  предоставление гарантии как на само изделие, так и на оказываемые услуги по установке
                  кессонов и погребов;
               </li>
               <li>сервисное обслуживание и поддержка квалифицированного инженера.</li>
            </ul>
            <p>
               ТМ «Земляк» много лет поставляет на отечественный рынок пластиковые погреба и кессоны
               собственного производства.
            </p>
            <p>Эти изделия востребованы садоводами, дачниками и даже используются в сфере малого бизнеса.</p>
            <p>Выбирайте высококачественную продукцию напрямую от завода изготовителя по лучшим ценам.</p>
         </div>
      </article>
   );
});
